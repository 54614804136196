import Pagination from 'components/Pagination'
import moment from 'moment'
import React from 'react'
import { Link } from 'react-router-dom'

export default function ParticipantsList({ participantsList, meta, goTo }) {
  return (
    <div className="table-responsive">
      <table className="table participants-table  mt-3">
        <thead>
          <tr>
            <th>اسم الشركة</th>
            <th className="text-center"> رقم الترخيص</th>
            <th className="text-center">المدينة</th>
            <th className="text-center">رقم الهاتف</th>
            <th className="text-center">المشاركات</th>
            <th className="text-center">الفئة العمرية</th>
            <th className="text-center">تاريخ المشاركة</th>
            <th className="text-center"> </th>
          </tr>

        </thead>
        <tbody>
          {participantsList.map(item => {
            return (
              <tr key={item?.id}>
                <td className="text-start">
                  <span className="fw-bold" style={{ color: "var(--subMainColor)" }}>
                    {item?.name}
                  </span>
                </td>
                <td>{item?.passport_number}</td>
                <td>{item?.governorate || "-"}</td>
                <td>{item?.phone || "-"}</td>
                <td>{item?.entries_count || "-"}</td>
                <td>{item?.age_range || "-"}</td>
                <td>{item?.entries_dates[0] ? moment(item?.entries_dates[0]).format("DD/MM/yyyy") : "-"}</td>

                <td>
                  <Link to={`/participant/details/${item?.id}`}>
                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M15.5799 11.9999C15.5799 13.9799 13.9799 15.5799 11.9999 15.5799C10.0199 15.5799 8.41992 13.9799 8.41992 11.9999C8.41992 10.0199 10.0199 8.41992 11.9999 8.41992C13.9799 8.41992 15.5799 10.0199 15.5799 11.9999Z" stroke="#707170" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                      <path d="M11.9998 20.2702C15.5298 20.2702 18.8198 18.1902 21.1098 14.5902C22.0098 13.1802 22.0098 10.8102 21.1098 9.40021C18.8198 5.80021 15.5298 3.72021 11.9998 3.72021C8.46984 3.72021 5.17984 5.80021 2.88984 9.40021C1.98984 10.8102 1.98984 13.1802 2.88984 14.5902C5.17984 18.1902 8.46984 20.2702 11.9998 20.2702Z" stroke="#707170" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                    </svg>
                  </Link>
                </td>
              </tr>
            )
          })}

        </tbody>


      </table>
      {participantsList?.length > 0 ? <Pagination info={meta} goTo={goTo} /> : null}
    </div>
  )
}
