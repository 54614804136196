import BreadcrumpCustom from "components/Breadcrump";
import { DownloadIcon } from "components/icons/SharedIcons";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { downloadFiles } from "services/general";
import { viewParticipantDetails } from "services/participant";

export default function ParticipantDetails() {
  // global hooks
  const { id } = useParams();
  const navigate = useNavigate();
  // states
  const [participantData, setParticipantData] = useState(null);

  //** function
  async function getParticipantDate() {
    const res = await viewParticipantDetails(id);
    if (res?.status === 200) {
      setParticipantData(res?.data?.data);
    } else {
      navigate("/participant");
    }
  }

  useEffect(() => {
    if (id) {
      getParticipantDate();
    } else {
      navigate("/participant");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  async function downloadFileWithUUID(fileUUID, fileName = "") {
    if (!fileUUID) return;
    const res = await downloadFiles(fileUUID);
    if (res?.status === 200) {
      const blob = new Blob([res.data], {
        type: res.headers["content-type"],
      });
      const objectUrl = window.URL.createObjectURL(blob);
      const anchor = document.createElement("a");
      anchor.target = "_blank";
      anchor.href = objectUrl;
      anchor.setAttribute(
        "download",
        `${fileName || ""}-${moment().format("DD-MM-YYYY")}.${
          res.data.type.split("/")[1]
        }`
      );
      anchor.click();
      toast.success("تم تنزيل الملف بنجاح");
    }
  }

  if (!participantData) {
    return null;
  }

  return (
    <>
      <BreadcrumpCustom
        pageTitle={"المشاركين"}
        items={[
          { label: "الرئيسية", link: "/" },
          { label: "المشاركين", link: "/participant" },
          { label: participantData?.name },
        ]}
      />

      <div className="content-body participant-details">
        <div className="card border-0">
          <div className="card-body">
            <div className="participant-title">
              <h2 className="title">{participantData?.name}</h2>
              <p className="gender"> {participantData?.gender}</p>
            </div>
            <div className="row mt-3">
              <div className="col-lg-3 col-md-6">
                <div className="details">
                  <h4 className="title"> رقم التواصل</h4>
                  <p className="desc">{participantData?.phone || "-"}</p>
                </div>
              </div>
              <div className="col-lg-3 col-md-6">
                <div className="details">
                  <h4 className="title"> الفئة العمرية </h4>
                  <p className="desc"> {participantData?.age_range || "-"}</p>
                </div>
              </div>
              <div className="col-lg-3 col-md-6">
                <div className="details">
                  <h4 className="title"> المدينة </h4>
                  <p className="desc">{participantData?.governorate || "-"} </p>
                </div>
              </div>
              <div className="col-lg-3 col-md-6">
                <div className="details">
                  <h4 className="title"> رقم الترخيص </h4>
                  <p className="desc">
                    {participantData?.passport_number || "-"}{" "}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="content-header mt-5">
          <h3 className="title">
            المشاركات
            <span>{participantData?.entries?.length}</span>
          </h3>
        </div>

        <div className="presentation-available">
          <div className="accordion" id="accordionPanelsStayOpenExample">
            {participantData?.entries?.map((entry) => {
              console.log("entry", entry);
              return (
                <div className="accordion-item p-4" key={entry?.id}>
                  <h2 className="accordion-header">
                    <button
                      className="accordion-button"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#panelsStayOpen-collapseOne"
                      aria-expanded="true"
                      aria-controls="panelsStayOpen-collapseOne"
                    >
                      <p>
                        <span className="title">{entry?.title}</span>
                        <span className="desc">
                          {" "}
                          المشاركة:{" "}
                          {entry?.created_at
                            ? moment(entry?.created_at).format("DD/MM/yyyy")
                            : "-"}{" "}
                        </span>
                      </p>
                    </button>
                  </h2>
                  <div
                    id="panelsStayOpen-collapseOne"
                    className="accordion-collapse border-0 collapse show"
                  >
                    <div className="accordion-body">
                      <h4 className="title">الاجابات</h4>
                      {entry?.answers?.map((question) => {
                        return (
                          <div className="answers" key={question?.id}>
                            <div className="answer-details">
                              <h6 className="title">{question?.question}</h6>
                              {question.type === "attachment" && (
                                <>
                                  {question.answer.map((file) => (
                                    <div>
                                      <button
                                        className="btn px-0 mx-2 d-flex align-items-center gap-2"
                                        key={file.uuid}
                                        onClick={() =>
                                          downloadFileWithUUID(file.uuid)
                                        }
                                        title={file?.fileName}
                                      >
                                        <DownloadIcon />
                                        <p className="desc">{file?.fileName}</p>
                                      </button>
                                      <p className="desc border p-2">
                                        Note: {file?.note}
                                      </p>
                                    </div>
                                  ))}
                                </>
                              )}

                              {question.type === "only_attachment" && (
                                <>
                                  {question.answer.map((file) => (
                                    <>
                                      <button
                                        className="btn px-0 mx-2 d-flex align-items-center gap-2"
                                        key={file.uuid}
                                        onClick={() =>
                                          downloadFileWithUUID(file.uuid)
                                        }
                                        title={file?.fileName}
                                      >
                                        <DownloadIcon />
                                        <p className="desc">{file?.fileName}</p>
                                      </button>
                                    </>
                                  ))}
                                </>
                              )}

                              {question.type === "textarea" && (
                                <p className=" desc">{question?.answer}</p>
                              )}

                              {question.type === "country_select" && (
                                <>
                                  {question?.answer.map((answer, index) => (
                                    <p key={index} className=" desc">
                                      {answer?.label}
                                    </p>
                                  ))}
                                </>
                              )}

                              {question.type === "check_box" && (
                                <>
                                  {question?.answer.map((answer, index) => (
                                    <p key={index} className=" desc">
                                      {answer?.answer}
                                    </p>
                                  ))}
                                </>
                              )}

                              {question.type === "choice" && (
                                <p className=" desc">{question?.answer}</p>
                              )}

                              {question.type === "rate_range" && (
                                <p className=" desc">{question?.answer}</p>
                              )}

                              {question.type === "rate" && (
                                <p className=" desc">{question?.answer}</p>
                              )}
                            </div>
                          </div>
                        );
                      })}
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </>
  );
}
