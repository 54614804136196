import axios from "axios";

const URL = process.env.REACT_APP_API_URL + "/api/v1";
const fetchFilesURL = process.env.REACT_APP_FILEUPLOAD_URL + "/fetch";


export const fetchDashboardStatistics = async () => {
  return await axios
    .get(`${URL}/dashboard/statistics`)
    .then((res) => res)
    .catch((err) => err.response);
};


export const downloadFiles = async (uuid) => {
	return await axios
		.get(`${fetchFilesURL}/${uuid}`, { responseType: "blob" })
		.then((res) => res)
		.catch((err) => err.response);
};