import React from 'react'
import { Link } from 'react-router-dom'

export default function BreadcrumpCustom({ pageTitle, items = [] }) {
  console.log(items);

  return (
    <div className="content-header">
      <h1 className="title">
        {pageTitle}
      </h1>

      <nav aria-label="breadcrumb">
        <ol className="breadcrumb">
          {items.map((item, index) => {
            return (
              <li key={item.label} className="breadcrumbItem">
                {item.link && index < items.length - 1 ?
                  <Link to={item.link}>{item.label} </Link>
                  :
                  item.label
                }
                {index < items.length - 1 &&
                  <span className="mx-2">
                    <svg width="14" height="15" viewBox="0 0 14 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M8.74995 12.1199L4.94662 8.31655C4.49745 7.86738 4.49745 7.13238 4.94662 6.68322L8.74995 2.87988" stroke="#292D32" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
                    </svg>
                  </span>
                }
              </li>
            )
          })}
        </ol>
      </nav>
    </div>
  )
}
